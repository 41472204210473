import { Component } from '@angular/core';
import localeEs from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
import { AuthService } from './core/services/auth.service';

// Register the Spanish locale data
registerLocaleData(localeEs);

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'renting-web-web';
  public isAuthenticated: boolean = false;

  constructor(private authService: AuthService) {
  }

  ngOnInit(): void {
    this.authService.setTokenSessionStorage();
    this.authService.getTokenSessionStorage().subscribe(
      res => {
        this.isAuthenticated = res
      }
    )
  }
}
