import { FinancingService } from 'src/app/core/services/financing.service';
import { Injectable } from '@angular/core';
import { OperationData } from '../models/operations/operation-data';
import { catchError, of, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OperationService {
  public operations!: OperationData[]
  /* private operations: OperationData[] = [
    {
      operationNumber: '481457',
      companyName: 'Tech Solutions S.A.',
      vatNumber: 'B12345678',
      operationDate: '04/08/24',
      type: 'L',
      amount: 65000,
      total: 10204,
      status: 'Aprobado',
      adminData: {
        name: 'Ulises Bermúdez Marco',
        email: 'ulises@techsolutions.com',
        phone: '677 362 039'
      }  
    },
    {
      operationNumber: '411457',
      companyName: 'Inversiones Madrid S.L.',
      vatNumber: 'B23456789',
      operationDate: '04/08/24',
      type: 'R',
      amount: 65000,
      total: 10204,
      status: 'Pendiente',
      adminData: {
        name: 'Maria del Carmen López Yuste',
        email: 'mcarmenlopez@invermad.com',
        phone: '617 352 139'
      }
    },
    {
      operationNumber: '981457',
      companyName: 'Construcciones García S.L.',
      vatNumber: 'B34567890',
      operationDate: '04/08/24',
      type: 'L',
      amount: 65000,
      total: 10204,
      status: 'Denegado',
      adminData: {
        name: 'Julián García Rodríguez',
        email: 'juliangr@cgarcia.com',
        phone: '677 362 039'
      }
    },
    {
      operationNumber: '771457',
      companyName: 'Alimentos del Sur S.A.',
      vatNumber: 'B45678901',
      operationDate: '04/08/24',
      type: 'L',
      amount: 65000,
      total: 10204,
      status: 'Completado',
      adminData: {
        name: 'Juan Alberto García Estevez',
        email: 'juanagestev@alimentosur.com',
        phone: '677 362 039'
      }
    },
    {
      operationNumber: '321457',
      companyName: 'Transportes Ibéricos S.A.',
      vatNumber: 'B56789012',
      operationDate: '04/08/24',
      type: 'R',
      amount: 65000,
      total: 10204,
      status: 'Aprobado',
      adminData: {
        name: 'Rosa Parra Domínguez',
        email: 'rosaparrad@tribericos.com',
        phone: '677 362 039'
      }
    },
    {
      operationNumber: '481457',
      companyName: 'Tech Solutions S.A.',
      vatNumber: 'B12345678',
      operationDate: '2024-08-10',
      type: 'L',
      amount: 65000,
      total: 10204,
      status: 'Aprobado',
      adminData: {
        name: 'Ulises Bermúdez Marco',
        email: 'ulises@techsolutions.com',
        phone: '677 362 039'
      }  
    },
    {
      operationNumber: '411457',
      companyName: 'Inversiones Madrid S.L.',
      vatNumber: 'B23456789',
      operationDate: '2024-08-11',
      type: 'R',
      amount: 65000,
      total: 10204,
      status: 'Pendiente',
      adminData: {
        name: 'Maria del Carmen López Yuste',
        email: 'mcarmenlopez@invermad.com',
        phone: '617 352 139'
      }
    },
    {
      operationNumber: '981457',
      companyName: 'Construcciones García S.L.',
      vatNumber: 'B34567890',
      operationDate: '04/08/24',
      type: 'L',
      amount: 65000,
      total: 10204,
      status: 'Denegado',
      adminData: {
        name: 'Julián García Rodríguez',
        email: 'juliangr@cgarcia.com',
        phone: '677 362 039'
      }
    },
    {
      operationNumber: '771457',
      companyName: 'Alimentos del Sur S.A.',
      vatNumber: 'B45678901',
      operationDate: '04/08/24',
      type: 'L',
      amount: 65000,
      total: 10204,
      status: 'Completado',
      adminData: {
        name: 'Juan Alberto García Estevez',
        email: 'juanagestev@alimentosur.com',
        phone: '677 362 039'
      }
    },
    {
      operationNumber: '321457',
      companyName: 'Transportes Ibéricos S.A.',
      vatNumber: 'B56789012',
      operationDate: '04/08/24',
      type: 'R',
      amount: 65000,
      total: 10204,
      status: 'Aprobado',
      adminData: {
        name: 'Rosa Parra Domínguez',
        email: 'rosaparrad@tribericos.com',
        phone: '677 362 039'
      }
    }
  ]; */

   constructor(
    private financingService: FinancingService
   ) {
    
   }

/*   getOperations(pageIndex: number, pageSize: number): any{
    this.financingService.getFinancingList(sessionStorage.getItem('userId')!).pipe(
      tap( res =>{
        this.operations = res.data;
        const startIndex = pageIndex * pageSize;
        const endIndex = startIndex + pageSize;
        return this.operations?.slice(startIndex, endIndex);
      }),
      catchError(error => {
        console.error(error)
        return of (null)
      })
    ).subscribe()

  }

  getOperation(operationNumber: string): OperationData | undefined {
    return this.operations?.find(op => op.operationNumber === operationNumber);
  }

  getTotalOperationsCount(): number {
    return this.operations?.length;
  }

  addOperation(operation: OperationData) {
    this.operations?.push(operation);
  }  */
}
