import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { JwtHelperService, JwtModule } from '@auth0/angular-jwt';
import { SharedModule } from '../shared/shared.module';
import { ErrorInterceptor, JwtInterceptor } from './interceptors';
import { AuthService } from './services/auth.service';
import { AuthGuard } from './services/guards/auth.guard.service';
import { LoginService } from './services/login/login.service';





@NgModule({
  declarations: [],
  imports: [
    SharedModule,
    CommonModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: function tokenGetter() {
          return sessionStorage.getItem('token');
        }
      }
    })
  ],
  providers: [
    AuthService,
    LoginService,
    JwtHelperService,
    AuthGuard,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ]
})

export class CoreModule { }
