export function translateState(state: string): string {
    switch (state.toLowerCase()) {
      case 'approved':
        return 'Aprobado';
      case 'denied':
        return 'Denegado';
      case 'pending':
        return 'Pendiente';
      case 'completed':
        return 'Completado';
      case 'processing':
        return 'Tramitando';
        case 'cancelled':
        return 'Cancelado';
      default:
        return state;
    }
  }