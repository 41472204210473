import { SharedModule } from './shared/shared.module';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OperationsListComponent } from './modules/operations-list/operations-list.component';
import { OperationDetailComponent } from './modules/operation-detail/operation-detail.component';
import { CustomMatPaginatorIntl } from './core/services/customMatPaginatorIntl';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { FormsModule } from '@angular/forms';
import { CurrencyPipe } from '@angular/common';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CoreModule } from './core/core.module';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

@NgModule({
  declarations: [
    AppComponent,
    OperationsListComponent,
    OperationDetailComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    FormsModule,
    CoreModule,
    
  ],
  providers: [
    CurrencyPipe,
    { provide: LOCALE_ID, useValue: 'es-ES' },
    { provide: MatPaginatorIntl, useClass: CustomMatPaginatorIntl },
    JwtHelperService,
    provideHttpClient(
      withInterceptorsFromDi()
    )
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
