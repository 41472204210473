import { FinancingService } from 'src/app/core/services/financing.service';
import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { OperationService } from 'src/app/core/services/operation.service';
import { OperationData } from 'src/app/core/models/operations/operation-data';
import { PageEvent } from '@angular/material/paginator';
import { catchError, debounceTime, of, tap } from 'rxjs';
import { translateState } from '../../core/extensions/states';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-operations-list',
  templateUrl: './operations-list.component.html',
  styleUrl: './operations-list.component.scss',
})
export class OperationsListComponent {
  public showFilters: boolean = false;
  length!: number; // length total de financiaciones
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions = [10, 20, 30];
  hidePageSize = false;
  showPageSizeOptions = true;
  showFirstLastButtons = true;
  disabled = false;
  pageEvent!: PageEvent;
  loader: boolean = false
  public offset: number = 10;

  public displayedColumns = [
    'operationNumber',
    'operationDate',
    'vatNumber',
    'companyName',
    'type',
    'amount',
    'status',
  ];
  public statusList: string[] = [
    'Inicial',
    'Aprobada',
    'Cancelada',
    'En Estudio',
  ];
  public operations: OperationData[] = [];
  public querySearch = new FormControl();
  public placeholder:string = "Buscar por operación, empresa o CIF/NIF";
  public searchText!: string;

  translateState = translateState

  constructor(
    private operationService: OperationService,
    private router: Router,
    private financingService: FinancingService
  ) { 
    this.querySearch.valueChanges.pipe(debounceTime(300)).subscribe(res => {
      this.searchText = res; 
      this.pageIndex = 0; 
      this.onSearch(); 
    });
}

  ngOnInit(): void {
    this.loadOperationsPage();
  }

loadOperationsPage() {
  const commercialId = sessionStorage.getItem('userId')!;
  const searchText = this.searchText || ''; 
  this.loader = true;
  this.financingService
    .findByFilter(commercialId, searchText, this.pageSize, this.pageIndex + 1)
    .pipe(
      tap((res) => {
        this.operations = res.data;
        this.length = res.total;
        this.loader = false;
      }),
      catchError((error) => {
        console.error(error);
        this.loader = false;
        return of(null);
      })
    )
    .subscribe();
}


  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex
    this.loadOperationsPage();
  }

  getStatusClass(state: string): string {
    switch (state.toLowerCase()) {
      case 'approved':
        return 'status-aprobado';
      case 'rejected':
        return 'status-denegado';
      case 'pending':
        return 'status-pendiente';
      case 'completed':
        return 'status-completado';
      case 'signed':
        return 'status-firmado';
      case 'cancelled':
        return 'status-cancelado';
      default:
        return '';
    }
  }

  openDetail(element: any) {
    this.router.navigate(['/operacion', element.operationId], {
      state: { operation: element },
    });
  }

  formatOperationId(operationId: number): string {
    return operationId.toString().padStart(6, '0');
  }

  onSearch(): void {
    const commercialId = sessionStorage.getItem('userId')!;
    const size = this.pageSize; // Tamaño de la página actual
    const page = this.pageIndex + 1; // El número de la página actual

    this.loader = true;
    this.financingService.findByFilter(commercialId, this.searchText, size, page)
      .pipe(
        tap((res) => {
          this.operations = res.data; // Actualiza las operaciones
          this.length = res.total; // Actualiza el total de resultados
          this.loader = false;
        }),
        catchError((error) => {
          console.error(error);
          this.loader = false;
          return of(null);
        })
      )
      .subscribe();
  }
}

