import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private apiUrl = `${environment.baseUrl}/customer`;

  constructor(private http: HttpClient) { }

  getUserIdByIdentifier(identifier: string): Observable<{ id: string }> {
    const params = new HttpParams().set('identifier', identifier);
    return this.http.get<{ id: string }>(this.apiUrl, { params });
  }
}
