<div class="op-detail">
  <div class="header-detail">
    <div class="back-button" routerLink="/operaciones">
      <img src="../../../assets/svg/Arrow-Left.svg" alt="">
      <p>Volver</p>
    </div>
  </div>
  <div class="op-data">
    <div class="title">
      <h1>Operación <span>{{ formatOperationId(operation.codeOperation) }}</span></h1>
      <p [ngClass]="getStatusClass(operation!.state)">
        {{ translateState(operation!.state) }}
      </p>
    </div>
  </div>
  <div class="op-container">
    <div class="client-container">
    <div class="content-op">
      <h2>DATOS CLIENTE</h2>
      <div class="client-data">
        <h3>{{operation?.customer.name}}</h3>
        <p class="cif">{{operation?.customer.identifier}}</p>
        <p class="name" *ngIf="operation?.customer.nameContact !== null" >{{operation?.customer.nameContact}} {{operation?.customer.surnameContact}}</p>
        <p class="email">{{operation?.customer.email}}</p>
        <p class="phone">{{operation?.customer.phone}}</p>
      </div>
    </div>
    <div class="content-op">
      <div class="client-data">
        <h3>Dirección de Facturación</h3>
        <p class="cif">{{customerDetails?.street}}</p>
        <p class="name" >{{customerDetails?.city}}</p>
        <p class="email">{{customerDetails?.province}}</p>
        <p class="phone">{{customerDetails?.zip}}</p>
      </div>
    </div>
    <div class="content-op">
      <div class="client-data">
        <h3>Dirección de Envío</h3>
        <p class="cif">{{shippingAddress?.street}}</p>
        <p class="name" >{{shippingAddress?.city}}</p>
        <p class="email">{{shippingAddress?.province}}</p>
        <p class="phone">{{shippingAddress?.zip}}</p>
      </div>
    </div>
  </div>
    <div class="resume">
      <h2>PRODUCTOS ({{operation.products.length}})</h2>
      @for (product of operation.products; track $index) {
      <div class="window-products">
        <div class="info">
          <h3><span>x{{product.quantity}}</span> {{product.name}}</h3>
          <h3>Marca</h3>
          <h3>Modelo</h3>
          <h3 *ngIf="product.comments">Comentarios</h3>
        </div>
        <div class="info-right">
          <p class="amount">{{this.showIva ? showAmountWithIva(product.unitPrice) :
            (product.unitPrice | currency:"€":"symbol":"1.2-2":"es")}}</p>
            <p class="amount">{{product.brand}}</p>
            <p class="amount">{{product.model}}</p>
            <p class="amount" *ngIf="product.comments">{{product.comments}}</p>
        </div>
        
      </div>}
      <h2 class="conditions">CONDICIONES FINANCIACIÓN</h2>
      <div class="window-financing">
        <div class="info">
          <h3>Tipo</h3>
          <h3 *ngIf="operation.type !== 'RENTING'">Entrada Inicial</h3>
          <h3>Plazo</h3>
          <h3>Carencia</h3>
        </div>
        <div class="info-data">
          <p class="amount">{{operation.type}}</p>
          <p class="amount" *ngIf="operation.type !== 'RENTING'">
            {{ this.showIva ? showAmountWithIva(entrada) : entrada | currency:"€":"symbol":"1.2-2":"es" }}
          </p>
          <p class="amount">{{operation.monthsTerm}} meses</p>
          <p class="amount">{{operation.gracePeriod}} meses</p>
        </div>

      </div>
      <div class="window-total">
        <div class="info">
          <h3>TOTAL A FINANCIAR</h3>
        </div>
        <p class="final-amount">{{this.showIva ? showAmountWithIva(valorFinanciado) : valorFinanciado| currency:"€":"symbol":"1.2-2":"es"}}</p>
      </div>
      <h2 class="conditions">RESULTADO OPERACION</h2>
      <div class="window-result">
      <div class="bottom-info">
        <div class="info">
          <p *ngIf="carencia > 0" class="fr-import-text strong">
            Importe mensual periodo de carencia (x{{ carencia }}
            meses)
          </p>
          <p *ngIf="carencia > 0," class="fr-import-text strong" >
            Importe mensual cuota regular <span *ngIf="operation.type !== 'RENTING'">(x{{
             operation.monthsTerm - carencia
            }}
            meses)</span><span *ngIf="operation.type === 'RENTING'">(x{{
              operation.monthsTerm
             }}
             meses)</span>
          </p>
          <h3 class="bold-result" *ngIf="carencia == 0">Importe mensual (x{{operation.monthsTerm}} meses)</h3>
          <h3>Comisión apertura (1%)</h3>
          <h3  *ngIf="operation.type !== 'RENTING'" >Última cuota</h3>
        </div>
        <div class="info-data">
          <p class="final-price" *ngIf="carencia > 0"
              >{{this.showIva ? showAmountWithIva(operation.installments[0].netPayment, true) : operation.installments[0].netPayment | currency : " " : "symbol" : "1.2-2" : "es"
              }}<span>€</span></p
            >
          <p class="final-price" >{{ this.showIva ? showAmountWithIva(monthlyPayment, true) : monthlyPayment | currency:" ":"symbol":"1.2-2":"es"}}<span>€</span></p>
          <h3>{{this.showIva ? showAmountWithIva(comisionApertura) : comisionApertura | currency:"€":"symbol":"1.2-2":"es"}}</h3>
          <h3 *ngIf="operation.type !== 'RENTING'" >{{this.showIva ? showAmountWithIva(ultimoPago) : ultimoPago | currency:"€":"symbol":"1.2-2":"es"}}</h3>
        </div>
      </div>
      </div>
      <div class="bottom-content">
        <div class="checkbox" (click)="toggleIVA()">
          <p>Mostrar precios con IVA</p>
          <input type="checkbox" [checked]="showIva"/>
        </div>
        <!-- <button class="gold-button" routerLink="/operaciones">
          CANCELAR OPERACIÓN
        </button> -->
      </div>
    </div>
  </div>
</div>