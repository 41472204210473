import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OperationsListComponent } from './modules/operations-list/operations-list.component';
import { GuestGuard } from './core/services/guards/guestguard.service';
import { AuthGuard } from './core/services/guards/auth.guard.service';
import { OperationDetailComponent } from './modules/operation-detail/operation-detail.component';

const routes: Routes = [
  {
    path: '', loadChildren: () => import("./modules/landing/landing.module").then(m => m.LandingModule), canActivate: [GuestGuard]
  },
  {
    path: 'operaciones', component: OperationsListComponent, canActivate: [AuthGuard]
  },
  {
    path: 'simulador', loadChildren: () => import("./modules/simulator/simulator.module").then(m => m.SimulatorModule), canActivate: [AuthGuard]
  },
  { path: 'operacion/:operationNumber', component: OperationDetailComponent },
  {
    path: '**', redirectTo:'', pathMatch: 'full'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
